import { useTranslation } from 'react-i18next';
import { Header, Footer } from '../header'
import { Content } from '../components/Content';
import { Catalog } from '../components/Catalog'
import {RialeGeoNet} from '../components/OS_LabsMap';

export const CatalogPage = () => {

  const { t } = useTranslation('frontend', { useSuspense: false });

  return (
    <>
      <Header className="mb-0 text-white" section={t("Catalogo - Aree tematiche, percorsi ed esperimenti")} showMenu={false} />
      <Content active="catalog"  className="pt-3">
        <Catalog />
      </Content>
    </>
  )
}

export const CatalogMap = () => {

  const { t } = useTranslation('frontend', { useSuspense: false });

  return (
    <>
      <Header className="mb-0 text-white" section={t("Catalogo - Mappa dei laboratori scientifici")} showMenu={false} />
      <Content active="map"  className="pt-3">
        <RialeGeoNet/>
      </Content>
    </>
  )
}