import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Alert,
  Card, CardHeader, CardBody, CardTitle, CardFooter, CardText, Container, Row, Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faVideo } from '@fortawesome/free-solid-svg-icons'
import {MdConnectedTv} from "react-icons/md";
import { getLivelloScolastico } from '../pages/constants'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { selectors as UiSelector, actions as UiActions } from '../store/slices/ui'
import {
  selectors as AppointmentsSelector, actions as AppointmentActions, Appointmentstate,
  isNoLongerAvailable
} from '../store/slices/appointments'
import { isSardinianTeacher, selectors as UsersSelectors, actions as UsersActions } from '../store/slices/users'
import { actions as CatalogActions, selectors as CatalogSelectors } from '../store/slices/catalog'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, IconButton, TextField, FormGroup, FormControlLabel } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from "classnames";
import QRCode from "react-qr-code";
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { CenteredText } from '../styles/styles';
import { ActivityButton, TooltipButton } from '../components/ActivityButton'
import { RESERVED_COLOR } from '../consts/colors'
import { AppointmentAttendeeViewer } from './Calendar/AppointmentAttendeesViewer';
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { INTERACTIVE_SESSION_TYPE, SYNCHRONOUS_SESSION_TYPE } from '../store/sagas/remotesessions';
import RialeAccordion from './RialeAccordion';
import { FaCopy, FaLink } from 'react-icons/fa';


const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm";

export const DirectorAppointmentEditor = (props: any) => {
  const dispatch = useDispatch();
  const closeModal = () => { dispatch(UiActions.closeDirectorAppointmentEditor({ "type": props.type })); };
  const isOpen = useSelector(UiSelector.isDirectorAppointmentEditorOpen(props.type));
  const initialData = useSelector(UiSelector.getInitialData);
  //const userAttributes = useSelector(ProfileSelectors.getProfile);
  //const attendeeAttributes = useSelector(UsersSelectors.getCurrentUserData)
  const users = useSelector(UsersSelectors.getUsers)
  const [usersList, setUsersList] = useState([]);
  const experiments = useSelector(CatalogSelectors.getExperiments);
  const directors = useSelector(AppointmentsSelector.getDirectors);
  const sessionDirectors = useSelector(
    AppointmentsSelector.getSessionDirectors(props.appointment != null ? props.appointment["id"] : ""));
  const [defaultDirectors, setDefaultDirectors] = useState([]);
  const [defaultGrantedUsers, setDefaultGrantedUsers] = useState([]);
  const [currentDirectors, setCurrentDirectors] = useState([]);
  const [currentGrantedUsers, setCurrentGrantedUsers] = useState([]);
  const [reservedAccess, isReservedAccess] = useState(false);
  const [reservedToSardinianTeachersAccess, isReservedToSardinianTeachers] = useState(false);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const [isQRModalOpen, setQRModalOpen] = useState(false);
  const areUpdatingSessionDirectors = useSelector(state => UiSelector.activityRunningSelector(state, "updateSessionDirectors"));

  const countries = useMemo(() => countryList(), []);


  const setCountryLabels = () => {
    countries.setLabel("IT", "Italiano");
    countries.setLabel("FR", "Français");
    countries.setLabel("GB", "English");
    countries.setLabel("NO", "Norvégien");
  }

  useEffect(() => {
    // modifica erroneamente il valore della Nazionalità nella pagina dei profili
    //setCountryLabels();

    if (props.appointment != null) {
      const reservedTo = props.appointment.reservedTo;
      //console.log(`default granted users (${props.appointment["id"]}):`, reservedTo);
      // La lista di default mostrata all'utente riguarda solo il caso in cui
      // si decida di spuntare la scelta di limitazione accessi, quindi non esiste
      // la opzione "null" di default
      setDefaultGrantedUsers(reservedTo == null ? [] : reservedTo.map((user: any) => user));
      // gli utenti correntemente ammessi sono inizialmente quelli di
      // default letti dal db!
      setCurrentGrantedUsers(reservedTo == null ? null : reservedTo.map((user: any) => user));
      isReservedAccess(reservedTo != null);
      isReservedToSardinianTeachers(props.appointment.reservedToSardinianTeachers)
    }

  }, [props.appointment])



  useEffect(() => {
    if (users == null) setUsersList([]);
    else {
      const list = Object.keys(users["registeredUsers"]).filter((index: any) => {
        ////console.log("BF1: REGUSER->:", index, users["registeredUsers"][index])
        return !reservedToSardinianTeachersAccess || isSardinianTeacher(users["registeredUsers"][index])
      }).map((key) => {
        return {
          "id": users["registeredUsers"][key]["id"], // fixed bug...before was "key"
          name: `${users["registeredUsers"][key]["given_name"]} ${users["registeredUsers"][key]["family_name"]} (${users["registeredUsers"][key]["email"]})`
        }
      }) as any;
      setUsersList(list);
    }
  }, [users, reservedToSardinianTeachersAccess])

  const renderQrcodeModal = (url: any, title: any) => {
    return (<Modal isOpen={url != null} >
      <ModalBody style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
          <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
            <CardTitle tag="h5">
              {title}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CenteredText>
              <QRCode style={{ marginTop: "20px" }} value={`${url}`} size={256} />
            </CenteredText>
          </CardBody>
          <CardFooter>
            <Alert>{t("Inquadra il qrcode col tuo cellulare e inoltra le tue domande ai tutor durante la sessione sincrona!")}</Alert>
          </CardFooter>
        </Card>

      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => { setQRModalOpen(false) }}>{t("Chiudi")}</Button>
      </ModalFooter>
    </Modal>)
  }


  const getDefaultSessionDirectors = (): any => {

    if (sessionDirectors == null || props.appointment == null) return [];
    //console.log("Individuati default directors: sessionDirectors: on: ", props.appointment["id"], sessionDirectors);
    const defDirectors = directors.filter((sessionDir: any) => {
      return sessionDirectors.some((sd: any) => sd["director"] == sessionDir["director"]);
    })

    //console.log("Individuati default directors:", defDirectors);
    return defDirectors;
  }

  const updateGrantedUsers = () => {
    let payload = { ...props.appointment };
    payload["reservedTo"] = (reservedAccess ? (currentGrantedUsers || []) : null);
    payload["reservedToSardinianTeachers"] = reservedToSardinianTeachersAccess;
    //console.log("BF1: GrantedUsers da salvare (payload) reservedTo:", payload);
    dispatch(AppointmentActions.willPutGrantedAttendeesInRemoteSession(payload));
  }

  const updateSessionDirectors = () => {
    //console.log("SessionDirectors da salvare:", currentDirectors);
    const payload = { "session": props.appointment["id"], directors: currentDirectors }
    //console.log("SessionDirectors da salvare:", payload);
    dispatch(AppointmentActions.willUpdateSessionDirectors(payload));
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 300,
        paddingTop: "5px", paddingBottom: "5px",
        '& > * + *': {
          marginTop: theme.spacing(3),
        },
      },
      tag: {
        height: 24,
        position: "relative",
        zIndex: 0,
        "& .MuiChip-label": {
          color: "#fff",
        },
        "& .MuiChip-deleteIcon": {
          color: "#a0a",
        },
        "&:after": {
          content: '""',
          right: 10,
          top: 6,
          height: 12,
          width: 12,
          position: "absolute",
          backgroundColor: "white",
          zIndex: -1,
        },
      },
      grayTag: { backgroundColor: "#888888", color: "white" },
      greenTag: { backgroundColor: "green", color: "white" },
      reservedToTag: { backgroundColor: RESERVED_COLOR, color: "white" },
      orangeTag: {
        backgroundColor: 'orange', color: "white"
      },
      blueTag: {
        backgroundColor: 'blue', color: 'white'
      }
    }
    ));

  const classes = useStyles();

  const getSurveyLink = (pre = true) => {
    const { appointment } = props;
    if (appointment == null) return "--";
    //https://survey.ideab3.it/riale/fisica/index.html?session=1-1312211030&stage=pre
    const startDate = moment(appointment.startDate).format("DDMMYYHHmm")
    const isPre = (pre == true ? "pre" : "post");
    const url = `https://survey.ideab3.it/riale/${appointment["organization"]}/index.html?session=1-${startDate}&stage=${isPre}`
    const urlMessage = isPre ? t("Sondaggio pre-sessione") : t("Sondaggio post-sessione")
    return <a target="_blank" href={url}>{urlMessage}</a>
  }

  const getStudentQuestionsUrl = () => {
    const url = experiments && experiments[initialData.title] && experiments[initialData.title]["qrcode_questions_url"]
    return url;
  }

  const getStudentQuestionsLink = () => {
    const url = getStudentQuestionsUrl();
    if (url != null) return (<a target="_blank" href={url}>{url}</a>)
    else return "--";
  }

  const getStudentSpreedsheetUrl = () => {
    const url = experiments && experiments[initialData.title] && experiments[initialData.title]["questions_spreadsheet_url"]
    if (url != null) return (<a target="_blank" href={url}>{t("Foglio di riepilogo")}</a>)
    else return "--";
  }

  const isSurveyAvailable = () => {
    return experiments && experiments[initialData.title] && experiments[initialData.title]["survey"] == true
  }

  const getMeetingUrl = () => {
    if (initialData.startUrl == null || initialData.startUrl.length < 1) return "--";
    return <a onClick={() => {
      const win = window.open(initialData.startUrl, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=640');
      win!.focus();
    }} href="#">Meeting{` `}{initialData.meetingId}</a>
  }


  const getMeetingUrlCopyLink = () => {
    return ( <div style={{ "float": "right" }}>
       <TooltipButton duration={2500} 
                     tooltipMessage = {`${t("Copia")}`}
                     onClickMessage={`${t('Copiato')}`}
                     onChildClick={async () => {
                      navigator.clipboard.writeText(initialData.joinUrl);
                      }}
                      >
            <FaCopy  cursor="pointer"
              style={{fontSize: 18, marginRight: "0px" , border: '1px solid #007bff"', }} color="#007bff"/>
      </TooltipButton>
    </div>)
  }

  const getCurrentExperimentName = () => {
    const currentEsperiment = experiments && experiments[initialData.title];
    if (currentEsperiment != null) return currentEsperiment["titolo"]
    else return "N.A"
  }

  const getAttendeesInfo =  () => {
    return (<AppointmentAttendeeViewer initialData={props.appointment} enableUnbooking={false as any} />)
  }

  const getStartAndDurationInfo = () => {
    const start = moment(initialData.startDate).format(DATE_TIME_FORMAT);
    const duration = moment(initialData.stopDate).diff(initialData.startDate) / 60000;

    return (
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
        <div><b>{t("Inizio")}:</b> {`${start}`}</div>
        <div><b>{t("Durata")}:</b> {`${duration}`} {t("minuti")}</div>
      </div>)
  }

  const renderGrantedAttendeesPanel = () => {
    return (

      <FormGroup>
        <FormControlLabel control={<Checkbox checked={reservedToSardinianTeachersAccess}
          disabled={reservedAccess==true}
          onChange={(value) => {
            isReservedToSardinianTeachers(value.currentTarget.checked);
            //console.log("Selected Solo docenti sardi Check:", value.currentTarget.checked)
          }} />}
          label={t("Consenti la prenotazione ai soli docenti sardi")} />

        <FormControlLabel control={<Checkbox checked={reservedAccess}
          onChange={(value) => {
            isReservedAccess(value.currentTarget.checked);
            //console.log("Selected Reserved Check:", value.currentTarget.checked)
          }} />}
          label={t("Consenti la prenotazione ai soli seguenti utenti")} />

        {
          reservedAccess && (<Autocomplete
            onChange={(event, newGrantedUsers: any) => {
              //console.log("Modifica Granted Users:", newGrantedUsers);
              setCurrentGrantedUsers(newGrantedUsers);
            }}
            multiple
            id="tags-outlined"
            options={usersList}
            getOptionLabel={(option) => `${(option as any).name}`}
            defaultValue={defaultGrantedUsers}
            filterSelectedOptions
            fullWidth
            renderTags={(value: any, getTagProps: any) =>
              value.map((option: any, index: any) => (
                <Chip
                  classes={{
                    root: classNames(classes.tag, {
                      [classes.reservedToTag]: true
                    })
                  }}
                  variant="outlined"
                  label={`${option.name}`}
                  {...getTagProps({ index })}
                />
              ))
            }

            renderInput={(params) => (
              <TextField
                style={{ paddingTop: "20px" }}
                {...params}
                variant="outlined"
                label={t("Utenti ammessi")}
                placeholder={t("Seleziona gli utenti")}
              />
            )}
          />

          )
        }
      </FormGroup>
    )
  }

  const renderRegiaPanel = () => {
    if (directors == null || directors.length < 1) {
      return <Alert color="danger">Non è stato possibile caricare i dati relativi alle regie</Alert>
    }
    else
      return <Autocomplete
        disabled={isNoLongerAvailable(props.appointment)}
        onChange={(event, newSessionDirectors: any) => {
          //console.log("Modifica Directors:", newSessionDirectors);
          setCurrentDirectors(newSessionDirectors);
          //dispatch(CatalogActions.setSubjectsFilter(newValue));
        }}
        multiple
        id="tags-outlined"
        options={directors.filter((director: any) => { return director && director["disabled"] != true })}
        getOptionLabel={(option) => `${(option as any).name}`}
        defaultValue={defaultDirectors}
        filterSelectedOptions
        fullWidth
        renderTags={(value: any, getTagProps: any) =>
          value.map((option: any, index: any) => (
            <Chip
              classes={{
                root: classNames(classes.tag, {
                  [classes.greenTag]: true
                })
              }}
              variant="outlined"
              label={`${option.name}`}
              {...getTagProps({ index })}
            />
          ))
        }

        renderInput={(params) => (
          <TextField
            style={{ paddingTop: "20px" }}
            {...params}
            variant="outlined"
            label={t("Regia")}
            placeholder={t("Seleziona la regia")}
          />
        )}
      />
  }

  useEffect(() => {

    //console.log("Individuati default directors: sessionDirectors on app:", props.appointment)
    const defaultDirectors = getDefaultSessionDirectors()
    setDefaultDirectors(defaultDirectors);
    setCurrentDirectors(defaultDirectors);
    //console.log("Nuovi default directors:", defaultDirectors);
  }, [sessionDirectors]);


  useEffect(() => {
    if (!areUpdatingSessionDirectors) { closeModal(); }
  }, [areUpdatingSessionDirectors]);


  const getInteractiveDeviceId = (initialData: any) => {
    if (initialData?.type == null || !initialData.type.startsWith(INTERACTIVE_SESSION_TYPE)) return null;
    const isessionId = initialData.type.split(`${INTERACTIVE_SESSION_TYPE}__`)[1]
    //console.log("ISESSION ID:", isessionId)
    return isessionId
  }

  const getInteractiveDeviceById = (ideviceId: any) => {
    const currentExperiment = experiments[initialData.title]
    if (currentExperiment == null) return { "titolo": "<Not found>" }
    const result = currentExperiment["interactive_devices"]?.filter((device: any) => {
      return device["id"] == ideviceId
    })
    if (result?.length > 0) return (result[0])
    else return { "titolo": "<Not found>" }
  }

  const renderISessionConnectionButton = () => {

    return (
      <IconButton style={{ borderRadius: "0", background: "#EEEEEE" }}
        color="secondary" onClick={(ev) => {
          const deviceId = getInteractiveDeviceId(initialData)
          const isessionUrl =
          `/isession/${props.appointment["organization"]}/${props.appointment["id"]}/${initialData.title}/${deviceId}`
          const win = 
          window.open(isessionUrl, 'winname', `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=${window.screen.width},height=${window.screen.height}`);
          win!.focus();
        }}><span style={{ marginRight: "5px", fontSize: 14, color: "#007bff" }}>{t("Connetti alla sessione")}
        </span>
        <MdConnectedTv  cursor="pointer"
              style={{fontSize: 20, marginRight: "0px" , border: '1px solid #007bff"', }} color="#007bff"/></IconButton>
    )

  }
  //@audit Director Live Camera connector
  const renderPTZ_CameraButton = () => {
    return (
      <IconButton style={{ borderRadius: "0", background: "#EEEEEE" }}
        color="secondary" onClick={(ev) => {
          const cameraUrl = `/director_live/${props.appointment["organization"]}/${props.appointment["id"]}/${initialData.title}`
          const win = window.open(cameraUrl, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=500,height=250');
          //const win = window.open('/live/123456','Controllo PTZ', "resizable,height=600,width=670");

          win!.focus();
        }}><span style={{ marginRight: "5px", fontSize: 14, color: "#007bff" }}>{t("Controllo Camera")}</span><FontAwesomeIcon style={{ fontSize: 18 }} color="#007bff" icon={faVideo as IconProp} /></IconButton>
    )
  }

  return (initialData && props.appointment && <Modal isOpen={isOpen}>
    {isQRModalOpen && renderQrcodeModal((getStudentQuestionsUrl()), getCurrentExperimentName())}
    <ModalHeader>{getCurrentExperimentName()}
    </ModalHeader>
    <ModalBody>
      <Card className="clearfix">
        <CardBody>
          <CardText><b>{t("Laboratorio")}:</b> {initialData.lab} </CardText>

          {
            props.type == INTERACTIVE_SESSION_TYPE && initialData?.title != null && (
              <>
                <CardText><b>{t("Esperienza")}:</b> {
                  getInteractiveDeviceById(getInteractiveDeviceId(initialData))["titolo"]
                } </CardText>
                <div style={{ marginBottom: "20px" }}>
                  <RialeAccordion title={`${t("Descrizione")}:`}> {
                    getInteractiveDeviceById(getInteractiveDeviceId(initialData))["descrizione"]
                  } </RialeAccordion>
                </div>
              </>

            )
          }

          {
            initialData.note != null && initialData.note.length > 0 && (
              <CardText><b>{t("Note")}:</b> {initialData.note} </CardText>
            )
          }


          {props.type != INTERACTIVE_SESSION_TYPE && (
            <div style={{ display: "flex", marginRight: "10px" }}>
              <CardText style={{ marginRight: "10px" }}>
                <b>{t("Lingua")}:</b>
              </CardText>
              <ReactCountryFlag
                countryCode={
                  (props.appointment["language"] || "IT") as any
                }
                style={{ fontSize: '1.4em' }}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                cdnSuffix="svg"
                title={countries.getLabel(props.appointment["language"] || "IT")}
              />
              <CardText style={{ marginLeft: "10px" }}>{countries.getLabel(props.appointment["language"] || "IT")}</CardText>
            </div>
          )}

          {
            isSurveyAvailable() && <CardText><b>{t("Sondaggio")}:</b> {getSurveyLink()} </CardText>
          }

          {props.type != INTERACTIVE_SESSION_TYPE && (
            <>
              <CardText><b>{t("Modulo domande studenti")}:</b>
                {getStudentQuestionsLink()}
                <div style={{ "float": "right" }}><a href="#"><FontAwesomeIcon icon={faQrcode as IconProp}
                  onClick={(ev) => {
                    setQRModalOpen(true)
                  }}
                  style={{ fontSize: 18 }} color="#007bff"
                /> </a>
                </div>
              </CardText>

              <CardText><b>{t("Riepilogo domande studenti")}:</b> {getStudentSpreedsheetUrl()} </CardText>
              <div style={{ "float": "right" }}>
                {getMeetingUrlCopyLink()}
              </div>
              <CardText><b>Zoom Meeting:</b> {getMeetingUrl()}

              </CardText>
              <CardText><b>Zoom Account:</b> {initialData.hostEmail} </CardText>
            </>
          )}

          {getStartAndDurationInfo()}
          <br></br>
          {

            <div> {getAttendeesInfo()}<br></br></div>
           
      
          }
          {props.type == SYNCHRONOUS_SESSION_TYPE && renderRegiaPanel()}
          {renderGrantedAttendeesPanel()}

        </CardBody>
      </Card>
    </ModalBody>
    <ModalFooter>
      <Container>
        <Row>
          <Col>
            {props.type == SYNCHRONOUS_SESSION_TYPE ? renderPTZ_CameraButton() : renderISessionConnectionButton() }
          </Col>
          <Col>
            <Button color="secondary" style={{ marginRight: "5px" }} onClick={() => { closeModal() }}>{t("Annulla")}</Button>
            <ActivityButton name="updateSessionDirectors" color="primary" onClick={() => {
              updateGrantedUsers();
              updateSessionDirectors();
            }}>{t("Conferma")}</ActivityButton>
          </Col>
        </Row>
      </Container>
    </ModalFooter>

  </Modal>)
}